import axios from "axios";
import { useState } from "react";
import { useContext } from "react"
import Sidebar from "../../components/sidebar/Sidebar"
import { Context } from "../../context/Context"
import "./settings.css"

export default function Settings() {
    const [file, setFile] = useState(null);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const {user, dispatch} = useContext(Context);
    const upload = process.env.REACT_APP_BACKEND_URL + "/api/upload"
    const baseURL = process.env.REACT_APP_BACKEND_URL + "/api/users/"

    const handleSubmit = async(e) =>{
        e.preventDefault();
        dispatch({type:"UPDATE_START"});
        const updatedUser = {
            userId:user._id,
            username,
            email,
            password,
        };

        if(file){
            // const data =  new FormData();
            // const filename = Date.now() + file.name;
            // data.append("name", filename);
            // data.append("file", file);
            // updatedUser.profilePic = filename;
            // try{
            //     await axios.post("https://oroblog.herokuapp.com/api/upload",data);
            // }catch(err){}
            const data =  new FormData();
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(e){
                var imgcode = e.target.result;
                console.log(imgcode);
                updatedUser.profilePic = imgcode;
                data.append("file",imgcode);
            }
            try{
                // await axios.post("/upload", data);
                await axios.post(upload,data);
            }catch(err){}
        }
        try {
            const res = await axios.put(baseURL +user._id, updatedUser);
            setSuccess(true);
            dispatch({type:"UPDATE_SUCCESS", payload:res.data});
        } catch (error) {
            dispatch({type:"UPDATE_FAILURE"});
        }
    };

    const handleDelete = async() => {
        try {
          await axios.delete(baseURL + user._id, {
            data:{username:user.username},
        });
          window.location.replace("/");
        } catch (error) {}
      };

  return (
    <div className="settings">
        <div className="settingsWrapper">
            <div className="settingsTitle">
                <span className="settingsUpdateTitle">Update Your Account</span>
                <span className="settingsDeleteTitle" onClick={handleDelete}>Delete Account</span>
            </div>
            <form className="settingsForm" onSubmit={handleSubmit}>
                <label>Profile Picture</label>
                <div className="settingsPP">
                    <img src={file ? URL.createObjectURL(file) : user.profilePic} alt="" />
                    <label htmlFor="fileInput">
                        <i className="settingsPPIcon far fa-circle-user"></i>
                    </label>
                    <input type="file" id="fileInput" style={{display:"none"}} onChange={e=>setFile(e.target.files[0])}/>
                </div>
                <label>Username</label>
                <input type="text" placeholder= {user.username} onChange={e=>setUsername(e.target.value)} />
                <label>Email</label>
                <input type="email" placeholder={user.email} onChange={e=>setEmail(e.target.value)} />
                <label>Passwrod</label>
                <input type="password" onChange={e=>setPassword(e.target.value)}/>
                <button className="settingsSubmit" type="Submit">Update</button>
                {success && <span style={{color:"green", textAlign:"center", marginTop:"20px"}}>Profile has been updated...</span>}
            </form>
        </div>
        <Sidebar/>
    </div>
  )
}
