import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"
import "./register.css"

export default function Register() {
  const [username,setUsername] = useState("")
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [error,setError] = useState(false)
  const baseURL = process.env.REACT_APP_BACKEND_URL + "/api/auth/5j4hk4"
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    try{
      const res = await axios.post(baseURL, {
        username,
        email,
        password
      });
      res.data && window.location.replace("/login");
    }catch(err){
      setError(true);
    }
  };
  return (
    <div className="Register">
      <span className="registerTitle">Register</span>
        <form className="registerForm" onSubmit={handleSubmit}>
          <label>username</label>
          <input 
            type="text"
            className="registerInput"
            placeholder="Enter your username..."
            onChange={e=>setUsername(e.target.value)}
          />
          <label>Email</label>
          <input
            type="text"
            className="registerInput"
            placeholder="Enter your email..."
            onChange={e=>setEmail(e.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            className="registerInput"
            placeholder="Enter your password..."
            onChange={e=>setPassword(e.target.value)}
          />
          {/* <button className="registerButton" type="submit">Register</button> */}
        </form>
        <button className="registerLoginButton">
        <Link className="link" to="/login">Login</Link>
        </button>
        {error && <span style={{color:"red", marginTop:"10px"}}>something went wrong!</span>}
    </div>
  )
}
